import Ranking from '../types/Ranking';
import * as config from '../config';
import { get } from 'http';

const baseUrl = 'https://us-central1-mysper-e5462.cloudfunctions.net';

export async function getTeamsRanking(teamId?: string | null, teamIds?: string | null, getAllTimeRanking?: boolean): Promise<Ranking> {
    return await getRanking('teams', 'averagePoints', 500, teamId, teamIds, getAllTimeRanking);
}

export async function getDivisionsRanking(teamId?: string | null, teamIds?: string | null, getAllTimeRanking?: boolean): Promise<Ranking> {
    return await getRanking('divisions', 'averagePoints', 5000, teamId, teamIds, getAllTimeRanking);
}

export async function getSubdivisionsRanking(teamId?: string | null, teamIds?: string | null, getAllTimeRanking?: boolean): Promise<Ranking> {
    return await getRanking('subdivisions', 'averagePoints', 5000, teamId, teamIds, getAllTimeRanking);
}

export async function getRanking(
    type: string,
    metric: string,
    maxTeams: number,
    teamId?: string | null,
    teamIds?: string | null,
    getAllTimeRanking?: boolean
): Promise<Ranking> {
    let url = baseUrl + '/getRanking?competitionId=' + config.COMPETITION_ID + '&type=' + type;

    url += `&metric=${metric}`;
    url += `&maxTeams=${maxTeams}`;
    url += `&allTime=${getAllTimeRanking ? 'true' : 'false'}`;

    if (teamIds) {
        url += `&teamIds=${teamIds}`;
    } else if (teamId) {
        url += `&teamId=${teamId}`;
    }

    const requestOptions: RequestInit = {
        method: 'GET',
        mode: 'cors',
        redirect: 'follow',
    };

    try {
        const response = await fetch(url, requestOptions);
        const json = await response.json();
        return json as Ranking;
    } catch (e) {
        console.log(e);
    }
    return {
        monthly: [],
        quarterly: [],
        highlighted: [],
        allTime: [],
    };
}
