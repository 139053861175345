import styled from 'styled-components';
import '../i18n';
import { useState, useEffect, useCallback } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { t, changeLanguage } from 'i18next';
import * as GoalsRestClient from '../services/GoalsRestClient';
import Ranking from '../types/Ranking';
import RankingEntry from '../types/RankingEntry';
import { useSearchParams } from 'react-router-dom';
import logo from '../assets/img/on-the-way.jpg';

export const COLOR = '#167b83';

interface MicrositeProps {}

const Microsite = (props: MicrositeProps) => {
    let [searchParams] = useSearchParams();

    const [subDivisionsListItems, setSubDivisionListItems] = useState<Array<object>>([]);
    const [divisionsListItems, setDivisionListItems] = useState<Array<object>>([]);
    const [divisionAfterTenRankingListItems, setDivisionTeamsAfterTenRankingListItems] = useState<Array<object>>([]);
    const [showAllTeamsDivisions, setShowAllTeamsDivisions] = useState(false);

    const setRankingList = useCallback(
        (
            sourceArray: Array<RankingEntry>,
            setFunction: (value: ((prevState: Array<object>) => Array<object>) | Array<object>) => void,
            highlightIds: string[],
            metric: string,
            scoreWidth: number
        ): void => {
            const itemsList = [];
            for (let i = 0; i < sourceArray.length; i++) {
                const isHighlighted = highlightIds && highlightIds.includes(sourceArray[i].id);
                itemsList.push(
                    <RankingListItem
                        key={'rankingListItem' + sourceArray[i].id}
                        backgroundColor={isHighlighted ? '#BBBBBB' : '#FFFFFF'}
                    >
                        <TextContainer width={10}>
                            <Text
                                fontSize={14}
                                fontColor={'#000000'}
                                fontWeight={isHighlighted ? 'bold' : 'normal'}
                            >
                                {sourceArray[i].position}.
                            </Text>
                        </TextContainer>
                        <TextContainer
                            width={85 - scoreWidth}
                            textAlign={'left'}
                        >
                            <Text
                                textAlign={'left'}
                                fontSize={14}
                                fontColor={'#000000'}
                                fontWeight={isHighlighted ? 'bold' : 'normal'}
                            >
                                {`${sourceArray[i].name}`}
                            </Text>
                        </TextContainer>
                        <TextContainer
                            width={scoreWidth}
                            textAlign={'right'}
                        >
                            <Text
                                fontSize={14}
                                fontColor={'#000000'}
                                fontWeight={isHighlighted ? 'bold' : 'normal'}
                                textAlign={'right'}
                                paddingRight={10}
                            >
                                {Number(sourceArray[i].score).toFixed(2)} {metric}
                            </Text>
                        </TextContainer>
                    </RankingListItem>
                );
                if (i < sourceArray.length) {
                    itemsList.push(<HorizontalRule key={'horizontalRule' + sourceArray[i].id} />);
                }
            }
            setFunction(itemsList);
        },
        []
    );

    const loadRankingListItems = useCallback(
        async (teamId: string | null, teamIds: string | null) => {
            const divPromise = GoalsRestClient.getDivisionsRanking(teamId, teamIds, true);
            const subDivPromise = GoalsRestClient.getSubdivisionsRanking(teamId, teamIds, true);

            let subdivisionsRankingData: Ranking = await subDivPromise;

            // aggregate subdivisions
            const aggregates: { [key: string]: RankingEntry } = {};
            for (let i = 0; i < (subdivisionsRankingData.allTime?.length || 0); i++) {
                if (!subdivisionsRankingData.allTime || !subdivisionsRankingData.allTime[i]) {
                    continue;
                }
                const entry = subdivisionsRankingData.allTime[i];
                if (aggregates[entry.id]) {
                    aggregates[entry.id].score += entry.score;
                } else {
                    aggregates[entry.id] = entry;
                }
            }

            const subdivisionsRanking: Array<RankingEntry> = [];
            Object.values(aggregates)
                .sort((a, b) => b.score - a.score)
                .forEach((entry, index) => {
                    subdivisionsRanking.push({ ...entry, position: index + 1 });
                });

            setRankingList(subdivisionsRanking, setSubDivisionListItems, [], t('POINTS_PER_PERSON'), 45);

            // Divisions
            let divisionsRanking: Ranking = await divPromise;
            setRankingList(
                (divisionsRanking.allTime || []).slice(0, 10),
                setDivisionListItems,
                divisionsRanking.highlighted,
                t('POINTS_PER_PERSON'),
                45
            );
            setRankingList(
                (divisionsRanking.allTime || []).slice(10),
                setDivisionTeamsAfterTenRankingListItems,
                divisionsRanking.highlighted,
                t('POINTS_PER_PERSON'),
                45
            );
        },
        [setRankingList, setSubDivisionListItems]
    );

    useEffect(() => {
        let teamId = searchParams.get('teamId');
        const teamIds = searchParams.get('teamIds');
        let locale = searchParams.get('locale') || navigator.language || 'en';
        changeLanguage(locale.substring(0, 2));
        loadRankingListItems(teamId, teamIds);
    }, [searchParams, loadRankingListItems]);

    return (
        <Container>
            <BackgroundHeader
                id={'nav'}
                gridArea={'nav'}
            />
            <VerticalGridContainer style={{ marginTop: -35 }}>
                <TableContainer>
                    <TitleContainer>
                        <Text
                            fontSize={18}
                            fontColor={'#000000'}
                            fontWeight={'bold'}
                        >
                            {`${t('RANKING')} ${t('BU_FU')}`}
                        </Text>
                    </TitleContainer>
                    {subDivisionsListItems}
                </TableContainer>
            </VerticalGridContainer>

            <VerticalGridContainer style={{ marginTop: 35 }}>
                <TableContainer>
                    <TitleContainer>
                        <Text
                            fontSize={18}
                            fontColor={'#000000'}
                            fontWeight={'bold'}
                        >
                            {`${t('RANKING')} ${t('LOCATION')}`}
                        </Text>
                    </TitleContainer>
                    {divisionsListItems}
                    {showAllTeamsDivisions && divisionAfterTenRankingListItems}
                    <RankingListItem
                        justifyContent={'flex-end'}
                        padding={8}
                        onClick={() => {
                            setShowAllTeamsDivisions(!showAllTeamsDivisions);
                        }}
                    >
                        <Text
                            fontSize={14}
                            fontWeight={'bold'}
                            fontColor={COLOR}
                        >
                            {showAllTeamsDivisions ? t('SEE_LESS') : t('MORE')} {showAllTeamsDivisions ? '↑' : '↓'}
                        </Text>
                    </RankingListItem>
                </TableContainer>
            </VerticalGridContainer>
        </Container>
    );
};

type TextContainerProps = {
    width: number;
    backgroundColor?: string;
    textAlign?: string;
};

type ContainerProps = {
    height?: number;
    width?: number;
};

type TableContainerProps = {};

type HorizontalRuleProps = {
    width?: number;
};

type TextProps = {
    fontSize?: number;
    fontColor?: string;
    fontWeight?: string;
    lineHeight?: number;
    align?: string;
    backgroundColor?: string;
    alignSelf?: string;
    textAlign?: string;
    paddingBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
};

type VerticalGridContainerProps = {
    backgroundColor?: string;
};

type BackgroundHeaderProps = {
    gridArea: string;
};

type RankingListItemProps = {
    padding?: number;
    justifyContent?: string;
    backgroundColor?: string;
};

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    min-height: 100vh;
`;

const BackgroundHeader = styled.div<BackgroundHeaderProps>`
    background-color: ${COLOR};
    min-height: 130px;
    width: 100%;
    display: flex;
    background-image: url(${logo});
    background-size: 200px;
    background-repeat: no-repeat;
    background-position-y: 20px;
    background-position-x: 8px;
`;

const TableContainer = styled.div<TableContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    width: 100%;
    max-width: 600px;
    overflow: hidden;
    background-color: #ffffff;
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid #eeeeee;
    border-radius: 15px;
`;

const Text = styled.span<TextProps>`
    color: ${(props) => props.fontColor};
    font-weight: ${(props) => props.fontWeight};
    font-size: ${(props) => props.fontSize}px;
    line-height: ${(props) => props.lineHeight};
    margin: 0px;
    text-align: ${(props) => props.align};
    background-color: ${(props) => props.backgroundColor};
    align-self: ${(props) => props.alignSelf};
    text-align: ${(props) => props.textAlign};
    padding-bottom: ${(props) => props.paddingBottom}px;
    padding-left: ${(props) => props.paddingLeft}px;
    padding-right: ${(props) => props.paddingRight}px;
    z-index: 2;
    font-family: 'Ubuntu';
`;

const VerticalGridContainer = styled.div<VerticalGridContainerProps>`
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '')};
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8px;
`;

const HorizontalRule = styled.hr<HorizontalRuleProps>`
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-color: #f0f0f0;
    color: #f0f0f0;
    background-color: #f0f0f0;
    width: ${(props) => (props.width ? props.width + '%' : '100%')};
    height: 1px;
`;

const TitleContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #dbdbdb;
    min-height: 10%;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    padding: 8px;
    box-sizing: border-box;
`;

const RankingListItem = styled.div<RankingListItemProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: ${(props) => props.padding}px;
    justify-content: ${(props) => props.justifyContent};
    width: 100%;
    background-color: ${(props) => props.backgroundColor || 'white'};
`;

const TextContainer = styled.p<TextContainerProps>`
    width: ${(props) => props.width}%;
    background-color: ${(props) => props.backgroundColor};
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
    padding-right: 8px;
`;

export default Microsite;
